<script setup>
import {computed} from "vue";

const props = defineProps({
    type: {
        type: String,
        default: 'success',
    },
    text: {
        type: String
    },
    submit: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const classesBtn = computed(() => {
    return {
        'primary': 'bg-blue-600 hover:bg-blue-700',
        'danger': 'bg-red-500 hover:bg-red-600',
        'success': 'bg-green-600 hover:bg-green-700'
    }[props.type]
})
</script>

<template>
    <button
        :class="[{'opacity-25 cursor-not-allowed': disabled }, classesBtn]"
        class="rounded-full px-5 h-10 text-white transition-colors"
        :disabled="disabled"
        :type="submit ? 'submit' : 'button'"
    >
        <div class="px-2 py-1.5">
                    <span class="px-0.5 uppercase leading-5">
                        {{ text }}
                    </span>
        </div>

    </button>
</template>

<style scoped>

</style>
